<!-- 
	This is the DataTables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>

	<div>

		<a-row :gutter="[24]" :span="20" :offset="2" :md="{span: 18, offset: 2}" class="padding-left-1 padding-right-1">
			<a-col :span="24">
				<!-- Simple Datatable card -->
				<a-card :bordered="true" class="header-solid mb-24" :bodyStyle="{padding: 0, paddingTop: '16px'}">
					<template #title>
						<h5 class="font-semibold">Companies</h5>
					</template>



					<div class="mx-25">
						<a-row type="flex" :gutter="24">
							<a-col :span="24" md="12">
								<a-select v-model="pageSize" @change="onPageSizeChange" style="width: 70px">
									<a-select-option value="5">5</a-select-option>
									<a-select-option value="10">10</a-select-option>
									<a-select-option value="15">15</a-select-option>
									<a-select-option value="20">20</a-select-option>
									<a-select-option value="25">25</a-select-option>
								</a-select>
								<label for="" class="ml-10">entries per page</label>
							</a-col>
							<a-col :span="24" md="12">

							</a-col>
						</a-row>
					</div>

					<a-table class="mt-20" :columns="columns2" :data-source="all_items" row-key="reference" :pagination="false" >
						<template slot="status" slot-scope="row">
							<a-icon :class="getStatusClass(row)" size="large" :type="row.active?'safety':'lock'" />
						</template>
						<template slot="organisation" slot-scope="row">
							<div class="author-info">
								<h6 class="m-0">{{ row.name }}</h6>
							</div>
						</template>
						<template slot="email" slot-scope="row">
							<div class="table-avatar-info">
								<div class="avatar-info">
									<h6>{{ row.email }}</h6>
								</div>
							</div>
						</template>

						<template slot="editBtn" slot-scope="row">
							<a-button type="link" @click="viewOrganisation(row)" :data-id="row.reference" class="btn-edit">
								E-Commerce
							</a-button>
						</template>
						<template slot="hrBtn" slot-scope="row">
							<a-button type="link" @click="viewHROrganisation(row)" :data-id="row.reference" class="btn-edit">
								HR
							</a-button>
						</template>
					</a-table>
					<div class="bottom_organisations"></div>
				</a-card>
				<!-- / Simple Datatable card -->
			</a-col>
		</a-row>


		
	</div>

</template>

<script>

    import { mapState } from 'vuex'


	// First table's list of rows.
	const data = [
		{
			key: '1',
			name: 'John Brown',
			age: 32,
			address: 'New York No. 1 Lake Park',
		},
		{
			key: '2',
			name: 'Jim Green',
			age: 42,
			address: 'London No. 1 Lake Park',
		},
		{
			key: '3',
			name: 'Joe Black',
			age: 32,
			address: 'Sidney No. 1 Lake Park',
		},
		{
			key: '4',
			name: 'Jim Red',
			age: 32,
			address: 'London No. 2 Lake Park',
		},
	];

	// Second table's list of columns.
    const columns2 = [
        {
            title: 'NAME',
            scopedSlots: { customRender: 'organisation' },
        },
        {
            title: 'EMAIL',
            scopedSlots: { customRender: 'email' },
        },
        {
            title: 'STATUS',
            scopedSlots: { customRender: 'status' },
        },
        {
            title: '',
            scopedSlots: { customRender: 'editBtn' },
            width: 50,
        },
        /*{
            title: '',
            scopedSlots: { customRender: 'hrBtn' },
            width: 50,
        },*/
    ];

	export default {
		components: {
		},
		data() {
			return {

			    all_items: [],
				// First table's list of columns.

				// First table's list of rows.
				data,

				// Second table's list of columns.
				columns2,

				// First table's number of rows per page.
				pageSize: 10,

				// Second table's number of rows per page.
				pageSize2: 10,
				
				// Second table's search query.
				query: '',

			}
		},
		mounted()
		{
            this.refreshPage()
            this.checkScroll('.bottom_organisations')
            this.scroll('.bottom_organisations')
		},
		methods: {

		    refreshPage()
			{
                this.$store.dispatch("COMMIT_SET_ORGANISATION_LIST_PAGINATED")
                this.onLoadMore()
			},
            onLoadMore(_page)
            {
                let _thisObj = this
                _thisObj.$store.dispatch("REQUEST_ORGANISATION_LIST_PAGINATED", {
                    email: _thisObj.auth_type !== "admin"?_thisObj.auth_user.email:null,
                    page: _page,
                    keyword: this.query
                })
                    .then((_response) => {

                    })
            },
            goToCreateOrganisation()
			{
			    this.$router.push({
					name: "organisationCreate"
				})
			},
            viewOrganisation(_item)
			{
			    this.$store.dispatch("COMMIT_SET_SELECTED_ORGANISATION", _item)
                this.$router.push({
                    name: "organisationHome"
                })
			},
            viewHROrganisation(_item)
            {
                this.$store.dispatch("COMMIT_SET_SELECTED_ORGANISATION", _item)
                this.$router.push({
                    name: "organisationHRHome"
                })
            },
			// Event handler for first table's size change.
			onPageSizeChange() {
				this.pageSize = parseInt( this.pageSize ) ;
			},

			// Event handler for second table's size change.
			onPageSize2Change() {
				this.pageSize2 = parseInt( this.pageSize2 ) ;
			},

			// Event handler for second table's search.
			onSearchChange() {
				if( this.query.length > 0 ) {
					this.data2 = data2.filter( (row) => {
						for( const key in row ) {
							if( row[ key ]
								.toString()
								.toLowerCase()
								.includes( this.query.trim().toLowerCase() ) )
								return true;
						}
						return false;
					}) ;
				}
				else {
					this.data2 = data2 ;
				}
			},
            checkScroll(_querySelector)
            {
                let scrolledTo = document.querySelector(_querySelector)
                if (scrolledTo && this.$isScrolledIntoView(scrolledTo)) {
                    /*console.log('scrolled')*/
                    if(this.currentPage < this.totalPages)
                    {
                        this.onLoadMore(this.currentPage+1)
                    }
                }
            },
            scroll (_querySelector) {
                window.onscroll = () => {
                    this.checkScroll(_querySelector)
                }
            },
		},
		computed: {
            ...mapState({
                auth_user: state => state.auth.user,
                auth_type: state => state.auth.type,
                auth_selected_role: state => state.auth.selected_role,
                auth_roles: state => state.auth.roles,
                list_items: state => state.organisation.list.paginated,


                pagination_data: state => state.organisation.pagination,
                pagination_links: state => state.organisation.links,
            }),
			hasAuthUser()
			{
			    return this.$itemExists(this.auth_user)
			},
            hasAuthRoles()
            {
                return this.hasAuthUser && this.$itemListExists(this.auth_roles)
            },
            isAuthenticated()
            {
				if(this.hasAuthRoles && this.$itemExists(this.auth_selected_role))
				{
				    let _thisObj = this
				    let _arr_filter = this.auth_roles.filter((_item) => {
				        return _thisObj.auth_selected_role.alias === _item.alias
					})
					if(_thisObj.$itemListExists(_arr_filter))
					    return true
                }

				return false
            },
			currentRole() {
                if(this.isAuthenticated)
                    return this.auth_selected_role

				return null
			},
			hasCurrentRole()
			{
			    return this.$itemExists(this.currentRole)
			},
			canActAsSystemAdmin()
			{
			    return this.hasCurrentRole && ["system-admin", "super-admin"].indexOf(this.currentRole.alias) >= 0
			},
            canActAsSupermAdmin()
            {
                return this.hasCurrentRole && ["super-admin"].indexOf(this.currentRole.alias) >= 0
            },
            hasPagination()
            {
                return this.$itemExists(this.pagination_data)
            },
            currentPage()
            {
                if(!this.hasPagination)
                    return 0

                return this.pagination_data.current_page
            },
            totalPages()
            {
                if(!this.hasPagination)
                    return 0

                return this.pagination_data.total_pages
            },
		},
        watch: {
            'list_items': function(_value)
            {
                this.all_items = _value;
            },
        }
	}

</script>

<style lang="scss">
</style>